export const aboutList = [
  {
    id: 1,
    label: "Р.Эрдэнэмөнх",
    title: "CEO",
    subTitle: "Chief Executive Officer",
    src: "assets/images/custom/cto.jpg",
  },
  {
    id: 2,
    label: "Э.Норзимаа",
    title: "BA",
    subTitle: "Business Analyst",
    src: "assets/images/custom/ba.jpg",
  },
  {
    id: 3,
    label: "Б.Мандуул",
    title: "SA",
    subTitle: "System Administrator/Analyst",
    src: "assets/images/custom/manduul.jpg",
  },
  {
    id: 4,
    label: "Б.Төгөлдөр",
    title: "SSE",
    subTitle: "Senior Software Engineer",
    src: "assets/images/custom/tuugii.jpg",
  },
  {
    id: 5,
    label: "Т.Берикжан",
    title: "DEV",
    subTitle: "Developer",
    src: "assets/images/custom/beku.jpg",
  },
  {
    id: 6,
    label: "Б.Адъяасүрэн",
    title: "DEV",
    subTitle: "Developer",
    src: "assets/images/custom/adiya.jpg",
  },
  {
    id: 7,
    label: "Б.Батжаргал",
    title: "DEV",
    subTitle: "Developer",
    src: "assets/images/custom/bachka.jpg",
  },
  {
    id: 8,
    label: "Б.Эрдэнэбаяр",
    title: "DEV",
    subTitle: "Developer",
    src: "assets/images/custom/eegii.jpg",
  },
];

export const services = [
  {
    icon: "icon-computer",
    color: "blue",
  },
  {
    icon: "icon-phone-2",
    color: "red",
  },
  {
    icon: "icon-edit",
    color: "green",
  },
  {
    icon: "icon-cloud",
    color: "orange",
  },
  {
    icon: "icon-play",
    color: "purple",
  },
  {
    icon: "icon-target",
    color: "blue",
  },
  {
    icon: "icon-happy",
    color: "red",
  },
];
