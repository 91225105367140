import "./App.css";
import { useEffect, useState } from "react";
import { aboutList, services } from "./utils/data";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import cogoToast from "cogo-toast";
import axios from "axios";
const baseURL = "https://aio.dmt.mn/api/method/dmt_aio.api.contuct_us";

const Error = ({ errors, touched, valKey }) => {
  return (
    <div
      style={{
        marginLeft: 22,
        color: "red",
      }}
    >
      {errors[valKey] && touched[valKey] && errors[valKey]}
    </div>
  );
};

const DrawerContent = ({ t, onClose = () => {} }) => {
  const onChange = (value) => {
    console.log("=>", value);
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    axios
      .post(baseURL, { ...values, user_phone: `+976-${values?.user_phone}` })
      .then(({ data }) => {
        if (data?.message) {
          setSubmitting(false);
          resetForm();
          onClose();
          cogoToast.success("This is a success message!");
        }
      })
      .catch((err) => {
        console.error("onSubmit error", err);
        setSubmitting(false);
        onClose();
        cogoToast.error("Error !");
      });
  };
  return (
    <div className="container-contact-us">
      <div class="banner_content">
        <h1 class="title_text mb-30 text_effect_wrap clearfix">
          <span class="text_effect text_effect_left">
            {t("contactUs.title")}
          </span>
        </h1>
      </div>
      <div class="contact_form" data-aos="fade-up" data-aos-delay="500">
        <Formik
          initialValues={{
            user_name: "",
            user_email: "",
            user_message: "",
            user_phone: "",
          }}
          validate={(values) => {
            const errors = {};
            Object.entries(values).forEach(([key, value]) => {
              if (!value) {
                errors[key] = "Required";
                return;
              }
              if (
                key === "user_email" &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.user_email
                )
              ) {
                errors.user_email = "Invalid email address";
              }
              if (
                key === "user_phone" &&
                !/^[0-9]{8}$/.test(values.user_phone)
              ) {
                errors.user_phone = "8 digit phone";
              }
              if (key === "user_name" && !/^[\D]+$/i.test(values.user_name)) {
                errors.user_name = "Non digit";
              }
            });
            return errors;
          }}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form_item">
                    <input
                      type="text"
                      name="user_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_name}
                      placeholder={t("contactUs.name")}
                    />
                    <Error
                      errors={errors}
                      touched={touched}
                      valKey={"user_name"}
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form_item">
                    <input
                      name="user_phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_phone}
                      placeholder={t("contactUs.phone")}
                    />
                    <Error
                      errors={errors}
                      touched={touched}
                      valKey={"user_phone"}
                    />
                  </div>
                </div>
              </div>

              <div class="form_item">
                <input
                  type="email"
                  name="user_email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.user_email}
                  placeholder={"****@**.***"}
                />
                <Error
                  errors={errors}
                  touched={touched}
                  valKey={"user_email"}
                />
              </div>

              <div class="form_item">
                <textarea
                  name="user_message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.user_message}
                  placeholder={t("contactUs.desc")}
                />
                <Error
                  errors={errors}
                  touched={touched}
                  valKey={"user_message"}
                />
              </div>
              <ReCAPTCHA
                style={{
                  marginBottom: 30,
                }}
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={onChange}
              />
              <button
                type="submit"
                class="btn bg_default_blue"
                disabled={isSubmitting}
              >
                {t("contactUs.btn")}
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

function App() {
  const { t, i18n } = useTranslation();
  const [lan, seTlan] = useState("us");

  const [isOpen, seTisOpen] = useState(false);

  const toggleDrawer = () => {
    seTisOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const changeLanguage = () => {
    seTlan((prev) => (prev === "us" ? "mn" : "us"));
    i18n.changeLanguage(lan === "us" ? "mn" : "en");
  };

  return (
    <>
      <body class={`home_agency   ${lan === "us" ? "english" : "mongolia"}`}>
        <div class="body_wrap">
          <div id="thetop"></div>
          <div id="backtotop">
            <a href="#" id="scroll">
              <i class="fal fa-arrow-up"></i>
              <i class="fal fa-arrow-up"></i>
            </a>
          </div>

          <header
            id="header_section"
            class="header_section sticky_header d-flex align-items-center clearfix"
          >
            <div class="container w-1520">
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-12">
                  <div class="brand_logo">
                    <a class="brand_link">
                      <img
                        src="assets/images/logo/dmt.png"
                        alt="logo_not_found"
                      />
                      <img
                        src="assets/images/logo/dmt.png"
                        alt="logo_not_found"
                      />
                    </a>
                  </div>
                </div>

                <div class="col-lg-8 col-md-12"></div>

                <div
                  class="col-lg-2 col-md-12 custom-lang"
                  style={{
                    display: "flex",
                    gap: 18,
                    alignItems: "center",
                  }}
                >
                  {/* <span
                    onClick={changeLanguage}
                    style={{
                      color: "#2e1091",
                      cursor: "pointer",
                      fontSize: 16,
                    }}
                  >
                    {lan !== "us" ? "English" : "Монгол"}
                  </span> */}

                  <span
                    onClick={toggleDrawer}
                    style={{
                      color: "#2e1091",
                      cursor: "pointer",
                      fontSize: 16,
                    }}
                  >
                    Contact us
                  </span>
                </div>
              </div>
            </div>
          </header>

          <main>
            <section
              id="banner_section"
              class="banner_section agency_banner deco_wrap d-flex align-items-center clearfix"
            >
              <div class="container w-1520">
                <div class="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
                  <div class="col-lg-7 col-md-7 col-sm-8 order-last">
                    <div class="banner_image scene">
                      <div class="big_image">
                        <div class="layer" data-depth="0.2">
                          <div data-aos="fade-up" data-aos-delay="300">
                            <img
                              style={{
                                width: "auto !important",
                              }}
                              src="assets/images/banner/10_software/img_1.png"
                              alt="image_not_found"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-5 col-md-6 col-sm-8">
                    <div class="banner_content">
                      <h1 class="title_text mb-30 text_effect_wrap clearfix">
                        <span class="text_effect text_effect_left">
                          {t("sectionOne.title_one")}
                        </span>
                        <span class="text_effect text_effect_left">
                          {t("sectionOne.title_two")}
                        </span>
                      </h1>
                      <p
                        class="mb-50"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        style={{
                          fontSize: 20,
                        }}
                      >
                        {t("sectionOne.sub_title")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="deco_image bg_1"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/banner/01_agency/shape_1.png"
                  alt="spahe_not_found"
                />
              </div>
              <div
                class="deco_image bg_2"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <img
                  src="assets/images/banner/01_agency/shape_2.png"
                  alt="spahe_not_found"
                />
              </div>

              <div class="clouds_wrap scene_1">
                <div class="deco_image cloud_1">
                  <div class="layer" data-depth="0.2">
                    <div data-aos="fade-up" data-aos-delay="700">
                      <img
                        src="assets/images/banner/01_agency/shape_3.png"
                        alt="spahe_not_found"
                        data-parallax='{"y" : 80}'
                      />
                    </div>
                  </div>
                </div>
                <div class="deco_image cloud_2">
                  <div class="layer" data-depth="0.2">
                    <div data-aos="fade-up" data-aos-delay="600">
                      <img
                        src="assets/images/banner/01_agency/shape_4.png"
                        alt="spahe_not_found"
                        data-parallax='{"y" : 50}'
                      />
                    </div>
                  </div>
                </div>
                <div class="deco_image cloud_3">
                  <div class="layer" data-depth="0.1">
                    <div data-aos="fade-up" data-aos-delay="800">
                      <img
                        src="assets/images/banner/01_agency/shape_5.png"
                        alt="spahe_not_found"
                        data-parallax='{"y" : 90}'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="testimonial_section"
              class="testimonial_section sec_ptb_120 deco_wrap clearfix"
            >
              <div class="container">
                <div class="section_title text-center mb-80 c_slide_in">
                  <h2 class="title_text mb-0">
                    <span class="c_slide_in_wrap1">
                      <span class="c_slide_in_wrap2">
                        <span class="c_slide_in_wrap3">
                          {t("sectionTwo.title")}
                        </span>
                      </span>
                    </span>
                  </h2>
                </div>

                <div
                  class="row justify-content-center"
                  style={{
                    width: "100%",
                  }}
                >
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div
                      id="testimonial_carousel_1"
                      class="testimonial_carousel_1 secondary_style dots_true nav_false owl-carousel owl-theme"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      {[...Array(5)].map((_, idx) => (
                        <div class="item text-center">
                          <span
                            style={{
                              fontSize: 32,
                              fontWeight: 600,
                              color: "#051441",
                            }}
                          >
                            {t(`sectionTwo.section_${idx + 1}.title`)}
                          </span>
                          <p class="mb-0 caru-text">
                            {t(`sectionTwo.section_${idx + 1}.content`)}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div class="deco_image shape_1" data-parallax='{"y" : 90}'>
                <img
                  src="assets/images/shapes/shape_25.png"
                  alt="image_not_found"
                  data-aos="fade-up"
                  data-aos-delay="200"
                />
              </div>
            </section>

            <section class="service_section sec_ptb_120 bg_gray deco_wrap clearfix">
              <div class="container w-1520">
                <div
                  class="section_title text-center mb-80"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <h2 class="title_text mb-0">Services We Provide</h2>
                </div>

                <div
                  id="service_carousel"
                  class="service_carousel arrow_right_left owl-carousel owl-theme"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {services.map(({ icon, color }, idx) => (
                    <div class="item">
                      <div
                        class="service_boxed_2"
                        data-background="assets/images/services/img_2.png"
                      >
                        <div class="item_header mb-30 clearfix">
                          <div class={`item_icon bg_gradient_${color}`}>
                            <i class={icon}></i>
                          </div>
                          <h3 class="item_title">
                            <a>{t(`sectionThree.section_${idx + 1}.title`)}</a>
                          </h3>
                        </div>
                        <p class="mb-0">
                          {t(`sectionThree.section_${idx + 1}.content`)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  class="deco_image spahe_1"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  <img
                    src="assets/images/shapes/shape_8.png"
                    alt="spahe_not_found"
                  />
                </div>
                <div
                  class="deco_image spahe_2"
                  data-aos="fade-left"
                  data-aos-delay="300"
                >
                  <img
                    src="assets/images/shapes/shape_9.png"
                    alt="spahe_not_found"
                  />
                </div>
              </div>
            </section>

            <section
              class="service_section sec_ptb_120 pb-0 clearfix"
              style={{
                marginBottom: 80,
              }}
            >
              <div class="container">
                <div class="section_title text-center mb-80 c_slide_in">
                  <h2 class="title_text mb-0 c_slide_in_wrap1">
                    <span class="c_slide_in_wrap2">
                      <span class="c_slide_in_wrap3">
                        {" "}
                        {t("sectionFour.title")}
                      </span>
                    </span>
                  </h2>
                </div>

                <div
                  class="tabs_nav ul_li"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <ul class="nav" role="tablist">
                    <li>
                      <a class="active" data-toggle="tab" href="#marketing_tab">
                        <span class="icon_image">
                          <img
                            src="assets/images/icons/react-logo.png"
                            alt="icon_not_found"
                          />
                        </span>
                        <strong>React</strong>
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#cloud_tab">
                        <span class="icon_image">
                          <img
                            src="assets/images/icons/frappe-logo.png"
                            alt="icon_not_found"
                          />
                        </span>
                        <strong>Frappe</strong>
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#website_tab">
                        <span class="icon_image">
                          <img
                            src="assets/images/icons/javascript.png"
                            alt="icon_not_found"
                          />
                        </span>
                        <strong>Java Script</strong>
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#mobile_tab">
                        <span class="icon_image">
                          <img
                            src="assets/images/icons/html5.png"
                            alt="icon_not_found"
                          />
                        </span>
                        <strong>HTML5</strong>
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#uiux_tab">
                        <span class="icon_image">
                          <img
                            src="assets/images/icons/python.png"
                            alt="icon_not_found"
                          />
                        </span>
                        <strong>Python</strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <section class="feature_section sec_ptb_120 deco_wrap clearfix">
              <div class="container">
                <div class="section_title text-center mb-30 c_slide_in">
                  <h2 class="title_text mb-0 c_slide_in_wrap1">
                    <span class="c_slide_in_wrap2">
                      <span class="c_slide_in_wrap3">
                        {t("sectionFive.title")}
                      </span>
                    </span>
                  </h2>
                </div>
                <div class="about-us">
                  {aboutList.map(({ label, title, subTitle, src }) => (
                    <div className="employer">
                      <img src={src} />
                      <p>{label}</p>
                      <strong
                        style={{
                          color: "#5e2ced",
                        }}
                      >
                        {title}
                      </strong>
                      <span> {subTitle}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div
                class="deco_image dot_image_2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/shapes/shape_25.png"
                  alt="shape_not_found"
                  data-parallax='{"y" : 80}'
                />
              </div>
            </section>
          </main>

          <footer
            id="footer_section"
            class="footer_section bg_dark_blue text-white deco_wrap clearfix"
          >
            <div class="footer_bottom">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="copyright_text">
                      <p class="mb-0">
                        Copyright © 2023 Data Mine Technologies
                      </p>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="social_icon ul_li_center">
                      <ul class="clearfix">
                        <li>
                          <a href="#!">
                            <i class="icon-facebook"></i>
                            <i class="icon-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i class="icon-twitter"></i>
                            <i class="icon-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i class="icon-vimeo"></i>
                            <i class="icon-vimeo"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i class="icon-linkedin"></i>
                            <i class="icon-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="footer_menu ul_li_right">
                      <ul class="clearfix">
                        <li>
                          <a href="#!">Terms of Use</a>
                        </li>
                        <li>
                          <a href="#!">Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </body>
      <Drawer
        style={{
          zIndex: 9999,
          minWidth: 600,
          background: "#f5f8ff",
        }}
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
      >
        <DrawerContent t={t} onClose={toggleDrawer} />
      </Drawer>
    </>
  );
}

export default App;
